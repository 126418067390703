

<template>
<span>

    <template>
        <v-layout row justify-end>
            <v-dialog v-model="dialog_editRastreio" persistent max-width="600px" overlay-color="blue-grey darken-3" overlay-opacity="0.5">
                <v-card>
                    <v-card-title>
                        <span class="headline">Código de Rastreamento</span>
                    </v-card-title>
                    <v-card-text>
                        <v-container grid-list-md>
                            <v-layout wrap>
                                <v-flex xs12>
                                    <v-text-field v-model="itemEdit.stor_or_cod_rastreio" label="Código de Rastreio" required outlined=""></v-text-field>
                                </v-flex>
                            </v-layout>
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue-grey darken-1" @click="dialog_editRastreio = false">Fechar</v-btn>
                        <v-btn color="light-green darken-3" @click="saveEditRastreio">Salvar</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-layout>
    </template>

    <v-toolbar flat color="white">
        <v-toolbar-title class="lime--text lighten-1">Gerenciar Pedidos</v-toolbar-title>
        <v-divider class="mx-2" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <template>
            <v-btn color="grey darken-2" dark class="mb-2" @click.prevent="atualizaDados">
                <v-icon dark>sync</v-icon>Atualizar
            </v-btn>
        </template>
    </v-toolbar>

    <v-card>
        <v-card-title>
            <v-text-field v-model="search" append-icon="search" label="Localizar" single-line hide-details></v-text-field>
        </v-card-title>
        <v-data-table :headers="headers" :items="loadStoreOrders" :search="search" :rowsPerPageItems="rowsPerPageItems">

            <template v-slot:[`item.acoes`]="{ item }">

                <v-tooltip top color="blue black--text">
                    <template v-slot:activator="{ on }">
                        <v-btn icon color="blue lighten-1" @click.prevent="editStoreOrderRastreio(item)" v-on="on">
                            <v-icon dark class="mdi mdi-truck-delivery-outline "></v-icon>
                        </v-btn>
                    </template>
                    <span>Código de Rastreio</span>
                </v-tooltip>

                <v-tooltip top color="lime black--text">
                    <template v-slot:activator="{ on }">
                        <v-btn icon color="lime lighten-1" @click.prevent="detailStoreOrder(item)" v-on="on">
                            <v-icon dark class="mdi mdi-reorder-horizontal"></v-icon>
                        </v-btn>
                    </template>
                    <span>Detalhes</span>
                </v-tooltip>

                <v-tooltip top color="red black--text">
                    <template v-slot:activator="{ on }">
                        <v-btn icon color="red lighten-1" @click.prevent="printStoreOrder(item)" v-on="on">
                            <v-icon dark class="mdi mdi-file-pdf-outline"></v-icon>
                        </v-btn>
                    </template>
                    <span>Gerar PDF</span>
                </v-tooltip>

            </template>

            <template v-slot:no-results>
                <v-alert :value="true" color="error" icon="warning">Voce procurou por "{{ search }}" nenhum item localizado.</v-alert>
            </template>
        </v-data-table>
    </v-card>
</span>
</template>

<script>
import {
    URL_BASE
} from "../../../config/configs";

export default {
    name: "StoreOrderComponent",
    created() {
        this.$store.dispatch("loadStoreOrders");
    },
    computed: {
        loadStoreOrders() {
            if (this.$store.state.storeorders.items.data == null) return [];

            return this.$store.state.storeorders.items.data;
        }
    },
    data() {
        return {
            search: "",
            rowsPerPageItems: [
                10,
                20,
                30,
                50,
                {
                    text: "$vuetify.dataIterator.rowsPerPageAll",
                    value: -1
                }
            ],
            headers: [{
                    sortable: false,
                    text: "ID",
                    value: "stor_or_id"
                },
                {
                    sortable: false,
                    text: "Pedido Nº",
                    value: "stor_or_codigo"
                },
                {
                    sortable: false,
                    text: "TID",
                    value: "stor_or_tid"
                },
                {
                    sortable: false,
                    text: "Data",
                    value: "stor_or_data"
                },
                {
                    sortable: false,
                    text: "Forma de Pagto",
                    value: "stor_or_forma_pagto"
                },
                {
                    sortable: false,
                    text: "Situação",
                    value: "stor_or_situacao"
                },
                {
                    sortable: false,
                    text: "Situação Pagto",
                    value: "stor_or_situacao_pagto"
                },
                {
                    text: "Ações",
                    align: "center",
                    sortable: false,
                    value: "acoes"
                }
            ],
            dialog_editRastreio: false,
            storeorder: [],
            itemEdit: [],
            base_url: URL_BASE
        };
    },
    methods: {
        atualizaDados() {
            this.$store.dispatch("loadStoreOrders");
        },
        detailStoreOrder(item) {
            this.$router.push({
                name: "store-order.detail",
                params: {
                    stor_or_id: item.stor_or_id
                }
            });
        },
        printStoreOrder(item) {
            this.$store.dispatch('printStoreOrder', item.stor_or_id)
                .then(response => {
                    this.openPdf(response.data)
                })
                .catch(error => {
                    
                })
        },
        openPdf(path) {
            javascipt:window.open(this.base_url + path, '_blank')
        },
        editStoreOrderRastreio(item) {
            this.itemEdit.stor_or_id = item.stor_or_id;
            this.itemEdit.stor_or_cod_rastreio = item.stor_or_cod_rastreio;
            this.dialog_editRastreio = true;
        },
        saveEditRastreio() {
            if ((this.itemEdit.stor_or_cod_rastreio === "") ) {
                this.$swal({
                    position: "center",
                    icon: "warning",
                    title: "Atenção",
                    text: "Campos Código de Rastreio é requerido",
                    showConfirmButton: true,
                    timer: 6000
                });
            } else {
                this.$store
                    .dispatch("edittStoreOrderRastreio", {
                        stor_or_id: this.itemEdit.stor_or_id,
                        stor_or_cod_rastreio: this.itemEdit.stor_or_cod_rastreio,
                    })
                    .then(response => {
                        if (response.data.success) {
                            this.dialog_editRastreio = false;
                            this.$swal({
                                position: "center",
                                icon: "success",
                                title: "Atenção",
                                text: response.data.message,
                                showConfirmButton: true,
                                timer: 6000
                            });
                        } else {
                            this.$swal({
                                position: "center",
                                icon: "warning",
                                title: "Atenção",
                                text: response.error,
                                showConfirmButton: true,
                                timer: 6000
                            });
                        }
                    })
                    .catch(error => {
                        console.log(error);
                        this.$swal({
                            position: "center",
                            icon: "error",
                            title: "Erro",
                            text: "Opss! Algo deu erradooooo",
                            showConfirmButton: true,
                            timer: 6000
                        });
                        this.errors = error.response;
                    });
            }
        },
    }
};
</script>

<style scoped>
.avatar {
    height: 40px;
    margin-top: 0.4rem;
}
</style>
